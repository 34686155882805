import styled from 'styled-components';

type ContainerProps = {
  hasHeight: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  height: ${(props) => (props.hasHeight ? '5vh' : '100vh')};
  justify-content: center;
  align-items: center;
  z-index: -1;
`;
