import React from 'react';
import {
  CalendarNavBarHeaderContainer,
  CalendarNavBarHeaderButton,
  CalendarNavBarHeaderText,
  CalendarNavBarHeaderTitle,
} from './styles';
import { NamesMonth } from '../../../utils/functions';
import { CalendarNavBarHeaderProps } from '../../../interfaces';

const CalendarNavBarHeader = ({
  month,
  nextMonth,
  previousMonth,
  onNextClick,
  onPreviousClick,
}: CalendarNavBarHeaderProps): React.ReactElement => {
  return (
    <>
      <CalendarNavBarHeaderTitle>
        {month.getFullYear()}
      </CalendarNavBarHeaderTitle>
      <CalendarNavBarHeaderContainer>
        <CalendarNavBarHeaderButton
          type="button"
          onClick={() => onPreviousClick()}
        >
          {NamesMonth[previousMonth.getMonth()]}
        </CalendarNavBarHeaderButton>
        <CalendarNavBarHeaderText>
          {NamesMonth[month.getMonth()]}
        </CalendarNavBarHeaderText>
        <CalendarNavBarHeaderButton type="button" onClick={() => onNextClick()}>
          {NamesMonth[nextMonth.getMonth()]}
        </CalendarNavBarHeaderButton>
      </CalendarNavBarHeaderContainer>
    </>
  );
};

export default CalendarNavBarHeader;
