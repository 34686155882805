import styled from 'styled-components';

export const CalendarNavBarHeaderContainer = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CalendarNavBarHeaderButton = styled.button`
  border: 0;
  background: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #a0a0a0;
  transition: 0.5s;
  padding: 8px;
  border-radius: 8px;
`;
export const CalendarNavBarHeaderText = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  color: #5a5a5a;
  padding: 8px 0 8px 0;
`;
export const CalendarNavBarHeaderTitle = styled.p`
  width: 100%;
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #5a5a5a;
`;
