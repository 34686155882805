import styled from 'styled-components';

export const ListEventsContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px auto;
  background: none;
  border: 0;
  width: 600px;
  @media only screen and (max-width: 700px) {
    width: 90%;
  }
`;

export const ContainerItem = styled.div`
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: white;
  width: 600px;
  height: 90px;
  background: linear-gradient(169.46deg, #65e8ff 0%, #44d8b4 100%);
  border-radius: 8px;
  padding: 8px;

  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const ContainerImg = styled.div`
  background: #47d4de;
  border-radius: 5;
  border-radius: 50%;
  padding: 10px;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  img {
    height: 45px;
  }
`;

export const ContainerSubItem = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
`;

export const RowInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 14px;
`;

export const ContainerText = styled.h6`
  text-align: left;
  font-weight: bold;
  color: white;
  width: 200px;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SubContainer = styled.div`
  padding: 20px;
  h1 {
    font-size: 24px;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;
