import styled from 'styled-components';

export const Container = styled.button`
  background: yellow;
  /* linear-gradient(180deg, #FFB800 5.73%, #FF5C00 100%) */
  background: linear-gradient(to right, #ffb800 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#ff5c00 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #ff5c00 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#ffb800 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 15px 1px, 1px 15px;
  border: 0;
  color: orange;
  font-size: 20px;
  height: 50px;
  padding: 0 16px;
  text-align: center;
  width: 300px;
  border-radius: 15px;
  transition: 0.2s;
  &:hover {
    opacity: 0.2;
  }

  &:focus {
    opacity: 1;
  }
  @media only screen and (max-width: 700px) {
    width: 250px;
    font-size: 20px;
  }
`;

export const ResponseDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background: -webkit-linear-gradient(180deg, #ffb800 5.73%, #ff5c00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
