import styled from 'styled-components';

export const Calendar = styled.aside`
  width: 100%;
  height: 100%;
  .DayPicker {
    border-radius: 10px;
  }
  .DayPicker-wrapper {
    padding-bottom: 0;
    margin: auto 300px;
    @media only screen and (max-width: 700px) {
      margin: 0;
    }
  }
  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }
  .DayPicker-Weekday {
    text-align: center;
    @media only screen and (max-width: 700px) {
      font-size: 10px;
    }
  }
  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin-top: 0;
    margin-bottom: 1em;
  }
  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }
  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    border-radius: 10px;
    color: #fff;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: none;
  }
  .DayPicker-Day--today {
    font-weight: normal;
  }
  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }
  .DayPicker-Day--selected {
    background: none !important;
    border-radius: 10px;
    color: #232129 !important;
  }
  .DayPicker-Caption {
    display: none;
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
`;
