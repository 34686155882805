import React, { useState, useCallback } from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useSpring, animated } from 'react-spring';
import { useMediaQuery } from 'react-responsive';
import { ContainerDiv, ChangeButton, DividerStyle } from './styles';
import Body from './body';

const Sidebar: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState(70);
  const mobile = useMediaQuery({ query: '(max-width: 700px)' });
  const props = useSpring({
    width: size,
    flex: 1,
    bottom: 0,
    position: 'fixed',
    height: '90vh',
    opacity: 1,
    from: { opacity: 1 },
  });
  const AnimatedView = animated(ContainerDiv);

  const handleExpandButton = useCallback(
    (data) => {
      setOpen(data);
      if (open === true) {
        setSize(70);
      } else {
        setSize(220);
      }
    },
    [open],
  );

  return (
    <>
      {!mobile ? (
        <AnimatedView style={props}>
          <ChangeButton type="button" onClick={() => handleExpandButton(!open)}>
            {open ? (
              <ChevronLeftIcon
                style={{ width: 50, height: 50, color: '#ffffff' }}
              />
            ) : (
              <ChevronRightIcon
                style={{ width: 50, height: 50, color: '#ffffff' }}
              />
            )}
          </ChangeButton>
          <DividerStyle />

          <div
            style={
              size === 220
                ? {
                    width: '100%',
                    height: 200,
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }
                : {
                    width: '100%',
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }
            }
          >
            <Body sideBarForm={size === 220} />
          </div>
        </AnimatedView>
      ) : (
        <ContainerDiv>
          <Body sideBarForm={mobile} />
        </ContainerDiv>
      )}
    </>
  );
};
export default Sidebar;
