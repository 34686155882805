import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  display: block;
  align-items: center;
  justify-content: center;
  margin-left: 450px;
  margin-right: 450px;
  margin-top: 100px;
  margin-bottom: 100px;
  height: 70%;
  border-radius: 8px;
  overflow-y: auto;

  @media only screen and (max-width: 700px) {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 90%;
  }
`;
