import { parse, format } from 'date-fns';
import RaffleIcon from '../Assets/rafflesIcon.svg';
import ScheduleIcon from '../Assets/scheduleIcon.svg';
import SurveyIcon from '../Assets/surveyIcon.svg';
import DashboardIcon from '../Assets/dashboardIcon.svg';
import { ToastProps } from '../interfaces';

function formatDateToSqlFormat(date: string): string {
  const dateParse = parse(date, 'dd/mm/yyyy', new Date());

  const result = format(dateParse, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

  return result;
}

function formatDateToSqlDate(data: string): Date {
  const day = data.split('/')[0];
  const month = data.split('/')[1];
  const year = data.split('/')[2];

  const dateSQL = `${year}-${month}-${Number(day)}`;
  return new Date(dateSQL);
}

const NamesMonth = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];
const NamesDays = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'];

function formatSqlDateToDateFormat(
  date: string | undefined,
  typeEventId: number,
): string {
  const formatDate = new Date(date !== undefined ? date : new Date());

  if (typeEventId === 1 || typeEventId === 4) {
    return `${formatDate.getDate()} de ${NamesMonth[formatDate.getMonth()]}`;
  }
  return `Encerra em ${formatDate.getDate()} de ${
    NamesMonth[formatDate.getMonth()]
  }`;
}

const iconsEvents = [ScheduleIcon, RaffleIcon, SurveyIcon, DashboardIcon];

function FinishDateToSurvey(item: Date): string {
  const date = new Date(item);
  return `Encerra em ${date.getDate() + 1} de ${NamesMonth[date.getMonth()]}`;
}

function convertRealForNumber(item: string): number {
  const number = item.replace(/[^0-9,]*/g, '').replace(',', '.');

  return Number(number);
}

function StringDate(date: string): string {
  const formatDate = new Date(date);

  const dateReturn = `${formatDate.getDate() + 1}/${
    formatDate.getMonth() + 1 >= 9
      ? formatDate.getMonth() + 1
      : `0${formatDate.getMonth() + 1}`
  }/${formatDate.getFullYear()}`;

  return dateReturn;
}

function GenerateToast(): ToastProps {
  return {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
}

export {
  formatDateToSqlFormat,
  formatDateToSqlDate,
  FinishDateToSurvey,
  formatSqlDateToDateFormat,
  StringDate,
  convertRealForNumber,
  GenerateToast,
  NamesMonth,
  NamesDays,
  iconsEvents,
};
