import React, { useState, useRef, useCallback } from 'react';

import { Modal } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { FiArrowLeft } from 'react-icons/fi';
import { Container } from '../../Modal/styles';
import Title from '../../Title';
import Input from '../../Input';
import Button from '../../Button';
import { SubContainer, FormContainer, TitleContainer } from './styles';
import api from '../../../Services/api';
import ButtonFormat from '../../ButtonFormat';
import { useCapptei } from '../../../Hooks/auth0';
import { GenerateToast } from '../../../utils/functions';

const CreateRaffle: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { count, setCount } = useCapptei();
  function handleOpen(): void {
    setVisible(true);
  }
  function handleClose(): void {
    setVisible(false);
  }
  const handlePayment = useCallback(
    async (data) => {
      try {
        await api.post('/raffle/create', data);
        toast.success('Sua rifa foi criada com sucesso!', GenerateToast());
        handleClose();
        setCount(count + 1);
      } catch (error) {
        toast.error('Ocorreu um erro ao criar a rifa!', GenerateToast());
      }
      handleClose();
    },
    [count, setCount],
  );
  return (
    <>
      <ButtonFormat onClick={() => handleOpen()}>Criar Rifa</ButtonFormat>
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Container>
          <SubContainer>
            <TitleContainer>
              <FiArrowLeft
                size={28}
                style={{ marginRight: 10 }}
                color="#737373"
                onClick={() => handleClose()}
              />
              <Title>Criar Rifa</Title>
            </TitleContainer>
            <FormContainer ref={formRef} onSubmit={handlePayment}>
              <Input name="name" placeholder="Nome da rifa" />
              <Input name="prizes" placeholder="Prêmios" />

              <Input name="lot" placeholder="Lote por aluno" />
              <Input
                name="closure"
                placeholder="Data de encerramento"
                mask="date"
                maxLength={10}
              />
              <Input
                name="drawDate"
                placeholder="Data de sorteio"
                mask="date"
                maxLength={10}
              />
              <Input
                name="priceForRaffles"
                placeholder="Valor por rifa"
                mask="currency"
              />
              <Button type="submit">Criar Rifa</Button>
            </FormContainer>
          </SubContainer>
        </Container>
      </Modal>
    </>
  );
};

export default CreateRaffle;
