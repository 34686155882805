import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div``;

export const Content = styled.div`
  margin: 70px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
`;

export const HeaderTitle = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const NumbersContainer = styled.div`
  display: Grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  margin: 8px;

  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const ButtonNumber = styled.button`
  background: linear-gradient(166.79deg, #65e8ff 0%, #44d8b4 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 8px;
  width: 150px;
  border: 0;
  height: 50px;
  margin: 16px;
  transition: 0.2s;
  &:hover {
    opacity: 0.5;
  }
  &:focus {
    opacity: 1;
  }
  @media only screen and (max-width: 700px) {
    width: 70px;
    border: 0;
    height: 40px;
    margin: 4px;
  }
`;

export const ContainerForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin: 8px;
  }
`;

export const ContainerInfoRaffleSell = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const TextInfoRaffleSell = styled.h2`
  font-size: 16px;
  margin-bottom: 8px;
  color: #009eba;
`;

export const TitleRafle = styled.h1`
  color: #737373;
  margin-bottom: 8px;
`;

export const NumbersContainerModal = styled.div`
  display: Grid;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
  align-items: center;
  justify-items: center;
  width: 80%;
  justify-content: space-between;
`;

export const NumbersContainerDiv = styled.div`
  background: linear-gradient(180deg, #ffb800 5.73%, #ff5c00 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 15px;
  width: 50px;
  border: 0;
  height: 20px;
  margin-top: 8px;
`;

export const CardSell = styled.div`
  width: 100%;
  background: #e0e0e0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding-left: 18px;
  padding-right: 18px;
  height: 40px;
  margin-bottom: 8px;
`;

export const TextSell = styled.h1`
  font-size: 14px;
  color: #a0a0a0;
  text-align: center;
`;

export const TextSellBold = styled.h1`
  font-size: 22px;
  font-weight: bold;
  color: #a0a0a0;
  text-align: center;
`;
