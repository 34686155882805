import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const CardPay = styled.div`
  background: linear-gradient(135deg, #65e8ff 0%, #44d8b4 100%);
  height: 200px;
  border-radius: 8px;
  border: 0;
  @media only screen and (max-width: 700px) {
    height: 80%;
  }
`;

export const CardPayTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  h2 {
    font-weight: bold;
  }
`;

export const SwiperStyle = styled(Swiper)`
  width: 100%;
  height: 40vh;
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: orange;
    border-radius: 50%;
  }

  /*move swiper pagination on top to image */
  .swiper-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    top: 0 !important;
    span {
      width: 10px;
      height: 10px;
      margin: 4px;
      border-radius: 50%;
      border: 1px solid black;
      cursor: pointer;
    }
  }
`;
