import styled from 'styled-components';
import { Form } from '@unform/web';

export const ContainerForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin-top: 20px;
  }
`;

export const SubContainer = styled.div`
  padding: 20px;
  h1 {
    font-size: 24px;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;
