import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header';
import Sidebar from '../../Components/Sidebar';
import { Container, Content } from './styles';
import { useCapptei } from '../../Hooks/auth0';
import { MySubCalendarProps, CalendarApiProps } from '../../interfaces';
import { formatDateToSqlDate } from '../../utils/functions';
import api from '../../Services/api';
import ReactDayPickerComponent from '../../Components/ScheduleComponents/ReactDayPickerComponent';

const Schedule: React.FC = () => {
  const { currentMonth, count } = useCapptei();
  const [events, setEvents] = useState<MySubCalendarProps[]>([]);
  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const getApiCalendar = await fetch(
          `https://api.calendario.com.br/?json=true&ano=${currentMonth.getFullYear()}&ibge=3550308&token=cGhic19AaG90bWFpbC5jb20maGFzaD0xMDg3OTU0NjE`,
        )
          .then((data) => data.json())
          .then((response) => response);
        const dateMonth: CalendarApiProps[] = getApiCalendar.filter(
          (response: any) => {
            const dataString = response.date.split('/');
            const data = new Date(
              dataString[2],
              dataString[1] - 1,
              dataString[0],
            );
            return data.getMonth() === new Date(currentMonth).getMonth()
              ? response
              : 0;
          },
        );

        const holidays = dateMonth.map((item) => {
          const obj = {
            title: item.name,
            start: formatDateToSqlDate(item.date),
            end: formatDateToSqlDate(item.date),
            allDay: true,
            resource: {
              locale: '',
              reason: '',
              typeEventId: 1,
            },
          };
          return obj;
        });

        const response = await api.get(
          `/events/get_mounth_event/${currentMonth.getFullYear()}/${
            currentMonth.getMonth() + 1
          }`,
        );
        const responseWithDate = response.data.map(
          (item: MySubCalendarProps) => {
            const obj = {
              allDay: item.allDay,
              end: new Date(item.end),
              resource: {
                locale: item.resource.locale,
                reason: item.resource.reason,
                typeEventId: item.resource.typeEventId,
              },
              start: new Date(item.start),
              title: item.title,
            };
            return obj;
          },
        );
        setEvents([...holidays, ...responseWithDate]);
      } catch (error) {
        console.log(error);
      }
    };
    load();
  }, [currentMonth, count]);

  return (
    <Container>
      <Header />
      <Sidebar />
      <Content>
        <h1>Agenda</h1>
        <ReactDayPickerComponent myEventsList={events} />
      </Content>
    </Container>
  );
};

export default Schedule;
