import React, { useState, useRef, useCallback } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Modal } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { Container } from '../../../Modal/styles';
import BankSlipLogo from '../../../../Assets/bankSlip.svg';
import ButtonBody from '..';
import Title from '../../../Title';
import Input from '../../../Input';
import Button from '../../../Button';
import { SubContainer, FormContainer, TitleContainer } from './styles';
import api from '../../../../Services/api';

const BankSlipPayButton: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const formRef = useRef<FormHandles>(null);

  function handleOpen(): void {
    setVisible(true);
  }
  function handleClose(): void {
    setVisible(false);
  }
  const handlePayment = useCallback(async (data) => {
    try {
      await api.post('finance/create', data);
      toast.success('O boleto foi enviado no seu e-mail', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setVisible(false);
    } catch (error) {
      toast.error('O boleto não foi gerado, tente novamente', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setVisible(false);
  }, []);
  return (
    <>
      <ButtonBody
        activePowerButton={() => handleOpen()}
        text="Gerar Boleto"
        img={BankSlipLogo}
      />
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Container>
          <SubContainer>
            <TitleContainer>
              <FiArrowLeft
                size={28}
                style={{ marginRight: 10 }}
                color="#737373"
                onClick={() => handleClose()}
              />
              <Title>Gerar Boleto</Title>
            </TitleContainer>
            <FormContainer ref={formRef} onSubmit={handlePayment}>
              <Input
                name="first_due_date"
                placeholder="Data de pagamento"
                mask="date"
              />
              <Input name="user_amount" placeholder="Valor" mask="currency" />
              <Input name="reason" placeholder="Motivo" />

              <Button type="submit" style={{ marginBottom: 20 }}>
                Gerar Boleto
              </Button>
            </FormContainer>
          </SubContainer>
        </Container>
      </Modal>
    </>
  );
};

export default BankSlipPayButton;
