import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { Modal } from '@material-ui/core';
import { FiArrowLeft } from 'react-icons/fi';
import { Container } from '../Modal/styles';

import { FormContainer, SubContainer, TitleContainer } from './styles';
import Input from '../Input';
import Button from '../Button';
import api from '../../Services/api';
import { TeamCreateProps } from '../../interfaces';
import { convertRealForNumber, GenerateToast } from '../../utils/functions';
import ButtonBody from '../DashboardComponents/Buttons';
import TeamLogo from '../../Assets/team.svg';
import Title from '../Title';

const TeamComponent = (): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  function handleOpen(): void {
    setVisible(true);
  }
  function handleClose(): void {
    setVisible(false);
  }

  const handleSubmit = useCallback(async (data: TeamCreateProps) => {
    try {
      const allInfo = {
        allStudents: data.allStudents,
        bankEmail: data.bankEmail,
        bankToken: data.bankToken,
        conclusion: data.conclusion,
        course: data.course,
        educationalInstitution: data.educationalInstitution,
        finalCollectionDate: data.finalCollectionDate,
        name: data.name,
        totalPrice: convertRealForNumber(data.totalPrice),
        amountPerMember:
          convertRealForNumber(data.totalPrice) / data.allStudents,
        tax: `"type": "fixed", "value": ${data.tax}`,
      };
      const response = await api.post('/team/new_team', allInfo);
      toast.success(
        `Sua turma foi criada. Código de turma: ${response.data.team_pass}`,
        GenerateToast(),
      );
    } catch (error) {
      toast.error(error.response.data, GenerateToast());
    }
    setVisible(false);
  }, []);

  return (
    <>
      <ButtonBody
        activePowerButton={() => handleOpen()}
        text="Criar turma"
        img={TeamLogo}
      />
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Container>
          <SubContainer>
            <TitleContainer>
              <FiArrowLeft
                size={28}
                style={{ marginRight: 10 }}
                color="#737373"
                onClick={() => handleClose()}
              />
              <Title>Criar turma</Title>
            </TitleContainer>

            <FormContainer ref={formRef} onSubmit={handleSubmit}>
              <Input name="name" type="text" placeholder="Nome da turma" />
              <Input
                name="educationalInstitution"
                type="text"
                placeholder="Instituição"
              />
              <Input name="course" type="text" placeholder="Curso" />
              <Input
                name="allStudents"
                type="text"
                placeholder="Quantidade de alunos"
              />
              <Input
                name="bankEmail"
                type="text"
                placeholder="E-mail de autenticação do pagseguro"
              />
              <Input
                name="bankToken"
                type="text"
                placeholder="Token de autenticação do pagseguro"
              />
              <Input
                name="conclusion"
                placeholder="Ano de conclusão"
                type="text"
                mask="date"
              />
              <Input
                name="finalCollectionDate"
                placeholder="Data de colação de grau"
                type="text"
                mask="date"
              />
              <Input
                name="totalPrice"
                type="text"
                placeholder="Valor a ser arrecadado"
                mask="currency"
              />
              <Input name="tax" type="number" placeholder="Taxa da turma" />
              <Button type="submit" style={{ marginBottom: 20 }}>
                Salvar
              </Button>
            </FormContainer>
          </SubContainer>
        </Container>
      </Modal>
    </>
  );
};
export default TeamComponent;
