/* eslint-disable camelcase */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { useParams, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingComponent from '../../Components/Loading';
import * as LoadingAnimation from '../../Assets/loading-animation.json';
import Input from '../../Components/Input';
import Button from '../../Components/Button';
import api from '../../Services/api';
import {
  Container,
  Content,
  HeaderTitle,
  NumbersContainer,
  ButtonNumber,
  ContainerInfoRaffleSell,
  TextInfoRaffleSell,
  ContainerForm,
  TitleRafle,
  NumbersContainerDiv,
  NumbersContainerModal,
  CardSell,
  TextSell,
  TextSellBold,
} from './styles';
import Title from '../../Components/Title';
import ModalComponent from '../../Components/Modal';
import {
  ParamsProps,
  RaffleBuyProps,
  SellingRaffleProps,
} from '../../interfaces';
import { GenerateToast } from '../../utils/functions';

const SellingRaffles: React.FC = () => {
  const { id } = useParams<ParamsProps>();

  const history = useHistory();
  const [count, setCount] = useState(0);
  const [allNumbers, setAllNumbers] = useState([]);
  const [nameRaffle, setNameRaffle] = useState();
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [price, setPrice] = useState(0);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const handleSelectedItem = useCallback(
    (idNumber: number) => {
      const alreadySelected = selectedItems.findIndex(
        (item) => item === idNumber,
      );

      if (alreadySelected >= 0) {
        const filteredItems = selectedItems.filter((item) => item !== idNumber);

        setSelectedItems(filteredItems);
      } else {
        setSelectedItems([...selectedItems, idNumber]);
      }
    },
    [selectedItems],
  );

  const handleSubmit = useCallback(
    async (data: RaffleBuyProps) => {
      try {
        setIsLoading(true);
        await api.post('/raffle/buy_all_number', {
          number: selectedItems,
          buyer_name: data.buyer_name,
          buyer_email: data.buyer_email,
          buyer_phone: data.buyer_phone,
          id: id[0],
        });
        setSelectedItems([]);
        setCount(count + 1);
        setIsLoading(false);
        history.push('/raffle');
        toast.success(
          'O comprovante e o boleto foram para seu e-mail.',
          GenerateToast(),
        );
      } catch (error) {
        setIsLoading(false);
        toast.error(error.response.data, GenerateToast());
      }
    },
    [count, id, history, selectedItems],
  );

  function NewStyle(number: number, available: boolean): object {
    if (selectedItems.includes(number) && available) {
      const obj = {
        background: 'linear-gradient(180deg, #FFB800 5.73%, #FF5C00 100%)',
      };
      return obj;
    }
    if (!available) {
      const obj = { background: '#E0E0E0', pointerEvents: 'none' };
      return obj;
    }
    const obj = {
      background: 'linear-gradient(135deg, #65E8FF 0%, #44D8B4 100%)',
    };
    return obj;
  }

  useEffect(() => {
    const load = async (): Promise<void> => {
      const response = await api.get(`/raffle/get_numbers_raffle/${id}`);
      setAllNumbers(response.data.numbers);
      setNameRaffle(response.data.name);
      setPrice(response.data.numbers[0].price);
      setIsLoading(false);
    };
    load();
  }, [id, count]);
  const total = price * selectedItems.length;

  if (isLoading) {
    return (
      <div>
        <LoadingComponent
          animation={LoadingAnimation}
          width={100}
          height={100}
        />
      </div>
    );
  }

  return (
    <Container>
      <Content>
        <HeaderTitle>
          <Link to="/raffle">
            <FiArrowLeft size={28} />
          </Link>
          <Title>Rifas</Title>
          <div />
        </HeaderTitle>
        <TitleRafle>{nameRaffle}</TitleRafle>
        {selectedItems.length === 0 ? undefined : (
          <ModalComponent TextButton="Vender Rifa">
            <ContainerForm ref={formRef} onSubmit={handleSubmit}>
              <Title>Vender Rifa</Title>
              {!selectedItems ? (
                <div />
              ) : (
                <NumbersContainerModal>
                  {selectedItems.map((item) => (
                    <NumbersContainerDiv key={item}>{item}</NumbersContainerDiv>
                  ))}
                </NumbersContainerModal>
              )}
              <Input name="buyer_name" placeholder="Nome" maxLength={10} />
              <Input name="buyer_email" placeholder="Email" />
              <Input name="buyer_phone" placeholder="Telefone" mask="phone" />
              <ContainerInfoRaffleSell>
                <TextInfoRaffleSell>
                  Valor por rifa: R$ {price.toFixed(2).replace('.', ',')}
                </TextInfoRaffleSell>
                <TextInfoRaffleSell>
                  Quantidade: {selectedItems.length}
                </TextInfoRaffleSell>
                <TextInfoRaffleSell>
                  Total: R$ {total.toFixed(2).replace('.', ',')}
                </TextInfoRaffleSell>
              </ContainerInfoRaffleSell>
              <Button type="submit">Enviar comprovante</Button>
            </ContainerForm>
          </ModalComponent>
        )}
        <NumbersContainer>
          {allNumbers.map((item: SellingRaffleProps) => (
            <ButtonNumber
              style={NewStyle(item.number, item.available)}
              key={item.number}
              onClick={() => handleSelectedItem(item.number)}
            >
              {item.number}
            </ButtonNumber>
          ))}
        </NumbersContainer>
        <TitleRafle>Vendidas</TitleRafle>
        <>
          {allNumbers.map((item: SellingRaffleProps) =>
            item.available === false ? (
              <CardSell key={item.number}>
                <TextSellBold>{item.number}</TextSellBold>
                <TextSell>{item.name}</TextSell>
                <TextSell>{item.phone}</TextSell>
              </CardSell>
            ) : (
              <div />
            ),
          )}
        </>
      </Content>
    </Container>
  );
};

export default SellingRaffles;
