import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { Container } from './styles';
import { Props } from '../../interfaces';

const RadioButton: React.FC<Props> = ({ name, options, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = '' } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.find((ref) => ref.checked)?.value || '';
      },
      setValue: (refs: HTMLInputElement[], id: string) => {
        const inputRef = refs.find((ref) => ref.id === id);
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs: HTMLInputElement[]) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);
  return (
    <Container>
      {options &&
        options.map((option, index) => {
          const newIndex = index + 1;
          return (
            <label htmlFor={option.id} key={newIndex}>
              <input
                ref={(ref) => inputRefs.current.push(ref as HTMLInputElement)}
                id={option.id}
                type="radio"
                name={name}
                defaultChecked={defaultValue.includes(option.id)}
                value={option.value}
                {...rest}
              />
              {option.label}
            </label>
          );
        })}
    </Container>
  );
};
export default RadioButton;
