import React from 'react';
import {
  AccordionWrapper,
  AccordionContent,
  AccordionItem,
  AccordionTitle,
  AccordionText,
  SubContent,
} from './styles';
import { AccordionProps } from '../../../interfaces';

const AccordionComponent: React.FC<AccordionProps> = ({
  title,
  time,
  response,
  children,
  color,
}) => {
  const [isOpen, setOpen] = React.useState(true);
  return (
    <AccordionWrapper
      color={
        !isOpen
          ? 'linear-gradient(180deg, #FFB800 5.73%, #FF5C00 100%);'
          : color
      }
      style={{ height: !isOpen ? 'auto' : 70 }}
    >
      <AccordionTitle isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
        {title}
      </AccordionTitle>
      {!isOpen ? (
        ''
      ) : (
        <SubContent>
          <AccordionText>{response}</AccordionText>
          <AccordionText>{time}</AccordionText>
        </SubContent>
      )}

      <AccordionItem isOpen={isOpen}>
        {!isOpen ? (
          <>
            <AccordionContent>{children}</AccordionContent>

            <SubContent>
              <AccordionText>{response}</AccordionText>
              <AccordionText>{time}</AccordionText>
            </SubContent>
          </>
        ) : (
          ''
        )}
      </AccordionItem>
    </AccordionWrapper>
  );
};

export default AccordionComponent;
