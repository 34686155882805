import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  header {
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    background: #f3f5f5;
    height: 10vh;
    margin: 0 auto;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    padding-left: 10px;
    padding-right: 10px;
    div {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
