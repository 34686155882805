import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  background: white;

  margin-left: 250px;
  margin-right: 250px;
  margin-top: 100px;
  margin-bottom: 100px;
  height: 400px;
  border-radius: 16px;
  @media only screen and (max-width: 700px) {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const ContainerAnswer = styled.div`
  background-color: rgb(196, 196, 196);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerDecision = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 200px;
  flex-direction: row;
`;

export const ButtonYes = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: green;
  border-radius: 8px;
  padding: 10px;
  color: #ffffff;
  font-size: 24px;
  margin: 10px;
  transition: 0.3s;

  &:hover {
    background-color: ${shade(0.4, 'green')};
  }

  &:focus {
    background-color: green;
  }
`;

export const ButtonNo = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  border-radius: 8px;
  padding: 10px;
  color: #ffffff;
  font-size: 24px;
  margin: 10px;
  transition: 0.3s;

  &:hover {
    background-color: ${shade(0.4, 'red')};
  }

  &:focus {
    background-color: red;
  }
`;
