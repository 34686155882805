import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  margin: 100px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 700px;
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
