import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  margin: 70px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 700px;
`;

export const RaffleText = styled.p`
  font-size: 16px;
  color: black;
`;
