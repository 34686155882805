import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

import Private from './private';
import Public from './public';
import Loading from '../Components/Loading';
import * as LoadingAnimation from '../Assets/loading-animation.json';

export const Routes: React.FC = () => {
  const { isAuthenticated, isLoading, error } = useAuth0();

  function LoadingRoutes(): JSX.Element {
    if (isLoading) {
      return (
        <div>
          <Loading animation={LoadingAnimation} width={100} height={100} />
        </div>
      );
    }
    if (isAuthenticated) {
      return <Private />;
    }
    return <Public />;
  }
  if (error) {
    return <div>Error</div>;
  }

  return LoadingRoutes();
};
export default Routes;
