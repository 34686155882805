import React from 'react';

import { ButtonBodyComponent, ImgBodyComponent } from './styles';
import { ButtonBodyProps } from '../../../interfaces';

const ButtonBody: React.FC<ButtonBodyProps> = ({
  img,
  text,
  activePowerButton,
}) => {
  return (
    <>
      <ButtonBodyComponent type="button" onClick={activePowerButton}>
        <ImgBodyComponent src={img} alt="capptei" />
        {text}
      </ButtonBodyComponent>
    </>
  );
};

export default ButtonBody;
