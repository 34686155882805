import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div``;
export const Content = styled.div`
  margin: 100px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  max-width: 700px;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    background: #ffffff;
    width: 50%;
  }
  h1 {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    color: #009eba;
    margin-bottom: 24px;
    font-size: 36px;
    font-weight: bold;
  }
  > button {
    margin-left: 300px;
    width: 300px;
  }

  a {
    color: #f4ede8;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#f4ede8')};
    }
  }
  @media only screen and (max-width: 700px) {
    form {
      background: #f3f5f5;
      button {
        width: 200px;
      }
      input {
        text-align: center;
      }
    }
  }
`;
export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  img {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }
  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #ff9000;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #312e38;
    }

    &:hover {
      background: ${shade(0.2, '#ff9000')};
    }
  }
`;
export const FormatInput = styled.div`
  padding: 16px;
  width: 70%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #a0a0a0;
  color: #636360;

  & + div {
    margin-top: 8px;
  }

  input {
    flex: 1;
    border: 0;
    background: transparent;
    color: blue;

    &::placeholder {
      color: #636360;
    }
  }
  > svg {
    margin-right: 16px;
  }
`;
export const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  justify-items: center;
  margin-bottom: 36px;
  input {
    width: 500px;
  }
  div {
    margin: 8px;
  }
  @media only screen and (max-width: 700px) {
    display: grid;
    grid-template-columns: 1fr;
    width: 350px;
    h4 {
      font-weight: bold;
      font-size: 18px;
    }
    input {
      width: 200px;
    }
  }
`;
