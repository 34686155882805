import React from 'react';
import Menu from '@material-ui/core/Menu';
import { MdMenu } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import { FiLogOut } from 'react-icons/fi';
import {
  ButtonHeaderLeft,
  ButtonMenu,
  ContainerName,
  ContainerBoxUserInfo,
  LinkButton,
} from './styles';

const MenuComponent: React.FC = () => {
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogout = (): void => {
    logout({
      returnTo: window.location.origin,
    });
  };
  return (
    <>
      <ButtonHeaderLeft type="button" onClick={handleClick}>
        <MdMenu size={40} color="#C4C4C4" />
      </ButtonHeaderLeft>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div style={{ width: 280, padding: 10 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <ContainerName>{user.name.substring(0, 1)}</ContainerName>
            <ContainerBoxUserInfo>
              <h4>{user.name}</h4>
              <h6>{user.email}</h6>
              <LinkButton to="/profile">Editar perfil</LinkButton>
            </ContainerBoxUserInfo>
          </div>
          <ButtonMenu onClick={() => handleLogout()}>
            <FiLogOut style={{ marginRight: 10 }} color="#737373" />
            <h6>Fazer logout</h6>
          </ButtonMenu>
        </div>
      </Menu>
    </>
  );
};

export default MenuComponent;
