import React from 'react';
import { TitleFirst } from './styles';

const Title: React.FC = ({ children }) => {
  return (
    <>
      <TitleFirst>{children}</TitleFirst>
    </>
  );
};

export default Title;
