import React, { useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import { Modal } from '@material-ui/core';
import { FiArrowLeft } from 'react-icons/fi';
import { ContainerForm, SubContainer, TitleContainer } from './styles';
import api from '../../../Services/api';
import 'react-toastify/dist/ReactToastify.css';
import ButtonFormat from '../../ButtonFormat';
import Button from '../../Button';
import Input from '../../Input';
import Title from '../../Title';
import { useCapptei } from '../../../Hooks/auth0';

import { Container } from '../../Modal/styles';
import { GenerateToast } from '../../../utils/functions';

const CreateSurvey: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [date, setDate] = useState('');
  const [surveyAnwsers, setSurveyAnwsers] = React.useState([
    { id: 0, anwser: '' },
  ]);
  const { count, setCount } = useCapptei();

  const [visible, setVisible] = useState(false);
  function handleOpen(): void {
    setVisible(true);
  }
  function handleClose(): void {
    setVisible(false);
  }

  const handleSubmit = useCallback(
    async (data) => {
      try {
        await api.post('/survey/new', data);
        setCount(count + 1);
        handleClose();
        setDate('');
        setSurveyAnwsers([{ id: 0, anwser: '' }]);
        toast.success('Sua enquete foi criada com sucesso!', GenerateToast());
      } catch (error) {
        setDate('');
        setSurveyAnwsers([{ id: 0, anwser: '' }]);
        setCount(count + 1);
        handleClose();
        toast.error('Sua enquete não foi criada!', GenerateToast());
      }
    },
    [count, setCount],
  );

  function addNewSurveyAnwser(): void {
    setSurveyAnwsers([...surveyAnwsers, { id: 0, anwser: '' }]);
  }
  function setSurveyItemValue(position: number, value: string): void {
    const updatedSurveyItems = surveyAnwsers.map((surveyAnwser, index) => {
      if (index === position) {
        return { ...surveyAnwser, value };
      }
      return surveyAnwser;
    });
    setSurveyAnwsers(updatedSurveyItems);
  }

  return (
    <>
      <ButtonFormat onClick={() => handleOpen()}>Criar Enquete</ButtonFormat>
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Container>
          <SubContainer>
            <TitleContainer>
              <FiArrowLeft
                size={28}
                style={{ marginRight: 10 }}
                color="#737373"
                onClick={() => handleClose()}
              />
              <Title>Criar Enquete</Title>
            </TitleContainer>
            <ContainerForm ref={formRef} onSubmit={handleSubmit}>
              <Input name="quest" placeholder="Pergunta" />

              {surveyAnwsers &&
                surveyAnwsers.map((surveyAnwser, index) => (
                  <Input
                    name={`${index + 1}`}
                    type="text"
                    key={`${index + 1}`}
                    placeholder={`Alternativa ${index + 1}`}
                    defaultValue={surveyAnwser.anwser}
                    onChange={(e) => setSurveyItemValue(index, e.target.value)}
                  />
                ))}
              <ButtonFormat
                onClick={() => addNewSurveyAnwser()}
                style={{ marginBottom: 20 }}
              >
                Adicionar respostas
              </ButtonFormat>
              <Input
                name="conclusion"
                placeholder="Data de encerramento"
                defaultValue={date}
                maxLength={10}
                mask="date"
              />
              <Button type="submit">Criar enquete</Button>
            </ContainerForm>
          </SubContainer>
        </Container>
      </Modal>
    </>
  );
};

export default CreateSurvey;
