import React from 'react';
// Providers
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from './styles/global';
import 'react-toastify/dist/ReactToastify.css';
// Route Components
import Routes from './Routes';

// import { Container } from './styles';
const App = (): React.ReactElement => {
  return (
    <BrowserRouter>
      <Routes />
      <GlobalStyle />
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
