import React from 'react';
import {
  Container,
  LinkStyle,
  ContainerDiv,
  TitleCard,
  CardDiv,
  ContainerText,
  TextStyle,
} from './styles';
import { NamesMonth } from '../../../utils/functions';
import { CardButtonProps } from '../../../interfaces';

const CardButton: React.FC<CardButtonProps> = ({
  sendTo,
  name,
  dateSorted,
  dateFinal,
  description,
}: CardButtonProps) => {
  function ConvertDateToDateString(item: string): string {
    const date = new Date(item);
    return `${date.getDate() + 1} de ${
      NamesMonth[date.getMonth()]
    } de ${date.getFullYear()}`;
  }
  return (
    <Container>
      <LinkStyle to={`/${sendTo}`}>
        <ContainerDiv>
          <CardDiv />
          <TitleCard>{name}</TitleCard>
          <CardDiv />
        </ContainerDiv>
        <ContainerText>
          <p>Sorteio</p>
          <TextStyle>{ConvertDateToDateString(dateSorted)}</TextStyle>
          <p>Venda até</p>
          <TextStyle>{ConvertDateToDateString(dateFinal)}</TextStyle>
          <p>Prêmios</p>
          <TextStyle>{description}</TextStyle>
          <p>Vendidas</p>
        </ContainerText>
      </LinkStyle>
    </Container>
  );
};

export default CardButton;
