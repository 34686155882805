import React, { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
import { useCapptei } from '../../Hooks/auth0';
import { PermissionComponentProps, TokenProps } from '../../interfaces';

const PermissionComponent: React.FC<PermissionComponentProps> = ({
  permissionAuth0,
  children,
}) => {
  const [permissions, setPermissions] = useState(false);
  const { token } = useCapptei();

  useEffect(() => {
    function loadRoles(): void {
      if (!token) {
        return;
      }
      const decodedToken: TokenProps = jwt(token);
      const findRole = decodedToken.permissions.some((r: string) =>
        permissionAuth0?.split(',').includes(r),
      );
      setPermissions(findRole);
    }

    loadRoles();
  }, [token, permissionAuth0]);

  return <>{permissions && children}</>;
};

export default PermissionComponent;
