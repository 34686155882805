import React from 'react';

import { useLocation, Link } from 'react-router-dom';
import RaffleIcon from '../../Assets/rafflesIcon.svg';
import SurveyIcon from '../../Assets/surveyIcon.svg';
import DashboardIcon from '../../Assets/dashboardIcon.svg';
import ScheduleIcon from '../../Assets/scheduleIcon.svg';
import { BodyProps } from '../../interfaces';

const Body: React.FC<BodyProps> = ({ sideBarForm }) => {
  const location = useLocation();

  return (
    <>
      <Link
        to={{
          pathname: '/dashboard',
          state: location.pathname,
        }}
      >
        <img src={DashboardIcon} alt="Resultados" style={{ height: 40 }} />
        {sideBarForm ? <p>Resultados</p> : ''}
      </Link>
      <Link
        to={{
          pathname: '/survey',
          state: location.pathname,
        }}
      >
        <img src={SurveyIcon} alt="Enquetes" />
        {sideBarForm ? <p>Enquetes</p> : ''}
      </Link>
      <Link
        to={{
          pathname: '/schedule',
          state: location.pathname,
        }}
      >
        <img src={ScheduleIcon} alt="Agenda" style={{ height: 40 }} />
        {sideBarForm ? <p>Agenda</p> : ''}
      </Link>
      <Link
        to={{
          pathname: '/raffle',
          state: location.pathname,
        }}
      >
        <img
          src={RaffleIcon}
          alt="Rifas"
          style={{
            marginRight: sideBarForm ? 20 : 10,
            marginLeft: sideBarForm ? 20 : 10,
          }}
        />
        {sideBarForm ? <p>Rifas</p> : ''}
      </Link>
    </>
  );
};

export default Body;
