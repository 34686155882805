import React, { useEffect, useState } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { NamesDays, NamesMonth } from '../../../utils/functions';
import CalendarNavBarHeader from '../CalendarNavBarHeader';
import DaysOfCalendar from '../DaysOfCalendar';
import { Calendar, ContainerButton } from './styles';
import CreateEvent from '../CreateEvent';
import PermissionComponent from '../../PermissionComponent';
import { useCapptei } from '../../../Hooks/auth0';
import { MyCalendarProps } from '../../../interfaces';
import ListEvents from '../ListEvents';
import 'react-day-picker/lib/style.css';

const ReactDayPickerComponent = ({
  myEventsList,
}: MyCalendarProps): React.ReactElement => {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const { setCurrentMonth } = useCapptei();

  useEffect(() => {
    const load = async (): Promise<void> => {
      if (selectedDate !== undefined) {
        setCurrentMonth(selectedDate);
      }
    };
    load();
  }, [selectedDate, setCurrentMonth]);

  return (
    <>
      <Calendar>
        <PermissionComponent permissionAuth0="write:calendar">
          <ContainerButton>
            <CreateEvent />
          </ContainerButton>
        </PermissionComponent>
        <DayPicker
          months={NamesMonth}
          disabledDays={[
            {
              before: new Date(),
            },
          ]}
          weekdaysShort={NamesDays}
          initialMonth={new Date()}
          onMonthChange={(month) => setSelectedDate(month)}
          selectedDays={myEventsList.map((item) => item.start)}
          renderDay={(date: Date, items: DayModifiers) => (
            <DaysOfCalendar day={date} modifiers={items} />
          )}
          navbarElement={({
            month,
            onNextClick,
            onPreviousClick,
            nextMonth,
            previousMonth,
          }) => (
            <CalendarNavBarHeader
              month={month}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
              nextMonth={nextMonth}
              previousMonth={previousMonth}
            />
          )}
        />
      </Calendar>
      {myEventsList.map((item, index: number) => (
        <ListEvents
          key={`${new Date(item.end).getMonth() + index}`}
          end={item.end}
          start={item.start}
          title={item.title}
          allDay={item.allDay}
          resource={item.resource}
        />
      ))}
      <div style={{ marginBottom: 100 }} />
    </>
  );
};

export default ReactDayPickerComponent;
