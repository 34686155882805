import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  margin-top: 70px;
  h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #009eba;
    margin-bottom: 24px;
    font-size: 36px;
    font-weight: bold;
  }
`;
