import styled from 'styled-components';

export const TitleFirst = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #009eba;
  margin-bottom: 24px;
  font-size: 36px;
  font-weight: bold;
`;
