import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';

export const ContainerDiv = styled.div`
  display: flex;
  bottom: 0;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
  background: linear-gradient(93.52deg, #65e8ff 0%, #44d8b4 100%);
  width: 70px;
  height: 90vh;
  position: fixed;
  z-index: 1;

  a {
    text-decoration: none;
    color: #ffffff;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      text-align: left;
    }
    img {
      height: 40px;
      margin: 10px;
    }
  }
  @media only screen and (max-width: 700px) {
    position: fixed; /*Força o rodapé sempre para o fim da página*/
    bottom: 0;
    top: auto;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background: linear-gradient(93.52deg, #65e8ff 0%, #44d8b4 100%);
    width: 100%;
    height: 12vh;
    border-radius: 8px 8px 0px 0px;

    a {
      display: flex;
      width: 25%;
      flex-direction: column;
      text-decoration: none;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 5px;
      margin-right: 5px;
      color: #ffffff;
      padding: 5px 5px 5px 5px;
      p {
        font-weight: bold;
        display: block;
      }
      img {
        height: 40px;
        margin: 0;
      }
    }
  }
`;

export const ChangeButton = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export const DividerStyle = styled(Divider)`
  width: 100%;
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;
