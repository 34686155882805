import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  body {
     background: #fff;
     color: #FFF;
     -webkit-font-smoothing: antialiased;
     @media only screen and (max-width: 700px) {
      background: #f3f5f5;
     }
  }
  body,input,button{
    font-family: "Montserrat";
    font-size: 16px;
  }
  h1,h2,h3,h4,h5,h6,strong{
    font-weight: 500;
  }
  button{
    cursor: pointer;
  }
`;
