import React, { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { useAuth0 } from '@auth0/auth0-react';
import logoImg from '../../../Assets/cappteiLogoMini.svg';
import { CardPay, CardPayTitle, SwiperStyle } from './styles';
import * as LoadingAnimation from '../../../Assets/loading-animation.json';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import api from '../../../Services/api';
import { useCapptei } from '../../../Hooks/auth0';
import { UserProps, TeamProps } from '../../../interfaces';
import LoadingComponent from '../../Loading';

SwiperCore.use([Navigation, Pagination]);

const Card: React.FC = () => {
  const { user } = useAuth0();
  const { token } = useCapptei();
  const [userResult, setUserResult] = useState<UserProps>();
  const [teamResult, setClasseResult] = useState<TeamProps>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoading(true);

      const userData = await api.get('/finance/user_sold', {
        params: {
          auth0_id: user.sub,
        },
      });
      const team = await api.get('/finance/team_sold', {
        params: {
          auth0_id: user.sub,
        },
      });

      setUserResult(userData.data);
      setClasseResult(team.data);
      setLoading(false);
    };
    load();
  }, [user, token]);

  const totalUserResult = userResult && userResult?.goal - userResult?.value;
  const totalTeamResult = teamResult && teamResult?.goal - teamResult?.value;

  if (loading) {
    return (
      <LoadingComponent
        animation={LoadingAnimation}
        width={100}
        height={100}
        hasContainerHeight
      />
    );
  }
  return (
    <SwiperStyle
      spaceBetween={50}
      slidesPerView={1}
      pagination={{
        clickable: true,
        bulletActiveClass: 'swiper-pagination-bullet-active',
        bulletElement: 'span',
        renderBullet: (index, className) => {
          return `
          <span class="${className} feature-pagination"> . </span>`;
        },
      }}
      style={{ width: '100%', height: '40vh' }}
    >
      <SwiperSlide style={{ paddingTop: 20 }}>
        <CardPay>
          <CardPayTitle>
            <div style={{ width: '25%' }} />
            <h2>Turma</h2>
            <img
              src={logoImg}
              alt="capptei"
              style={{ width: '25%', height: 30 }}
            />
          </CardPayTitle>
          <div
            style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}
          >
            <p>Saldo</p>
            <p style={{ fontWeight: 'bold' }}>
              {teamResult?.value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
            <p style={{ marginTop: 20 }}>Meta</p>
            <p style={{ fontWeight: 'bold' }}>
              {teamResult?.goal.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
            <p style={{ fontSize: 15 }}>
              Falta{' '}
              {totalTeamResult &&
                totalTeamResult.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
            </p>
          </div>
        </CardPay>
      </SwiperSlide>
      <SwiperSlide style={{ paddingTop: 20 }}>
        <CardPay>
          <CardPayTitle>
            <div style={{ width: '25%' }} />
            <h2>Meu saldo</h2>
            <img
              src={logoImg}
              alt="capptei"
              style={{ width: '25%', height: 30 }}
            />
          </CardPayTitle>
          <div
            style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}
          >
            <p>Saldo</p>
            <p style={{ fontWeight: 'bold' }}>
              {userResult?.value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
            <p style={{ marginTop: 20 }}>Meta</p>
            <p style={{ fontWeight: 'bold' }}>
              {userResult?.goal.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
            <p style={{ fontSize: 15 }}>
              Falta{' '}
              {totalUserResult &&
                totalUserResult.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
            </p>
          </div>
        </CardPay>
      </SwiperSlide>
    </SwiperStyle>
  );
};

export default Card;
