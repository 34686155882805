import React from 'react';
import { BsCheckBox } from 'react-icons/bs';
import { Container, TitleDisabled, TextInfoDisabled, Content } from './styles';
import { ContainerAccordionDisabledProps } from '../../../interfaces';
import CheckBoxIcon from '../../../Assets/checkbox.svg';

const ContainerAccordionDisabled: React.FC<ContainerAccordionDisabledProps> = ({
  options,
  title,
  votes,
}) => {
  const alternatives = options.map((item, index) => {
    const obj = {
      name: item.label,
      vote: votes[index],
    };
    return obj;
  });

  // sort by value
  const alternativesOrdered = alternatives.sort((a, b) => {
    return b.vote - a.vote;
  });

  return (
    <Container>
      <TitleDisabled>{title}</TitleDisabled>
      <div>
        {alternativesOrdered.map((item, index) => (
          <Content key={item.name}>
            {index === 0 ? (
              <>
                <BsCheckBox color=" #57D37A" />

                <TextInfoDisabled
                  style={{ color: '#57d37a' }}
                  title={item.name}
                >
                  {item.name}
                </TextInfoDisabled>

                <div
                  style={{
                    width: `${item.vote * 5}px`,
                    background: '#57D37A',
                    height: 3,
                    marginLeft: 8,
                  }}
                />
                <TextInfoDisabled style={{ color: '#57d37a' }}>
                  {item.vote === 1 ? `${item.vote} voto` : `${item.vote} votos`}
                </TextInfoDisabled>
              </>
            ) : (
              <>
                <img src={CheckBoxIcon} alt="" />
                <TextInfoDisabled>{item.name}</TextInfoDisabled>

                <div
                  style={{
                    width: `${item.vote * 5}px`,
                    background: '#C9C9C9',
                    height: 3,
                    marginLeft: 8,
                    borderRadius: 8,
                  }}
                />
                <TextInfoDisabled>
                  {item.vote === 1 ? `${item.vote} voto` : `${item.vote} votos`}
                </TextInfoDisabled>
              </>
            )}
          </Content>
        ))}
      </div>
    </Container>
  );
};

export default ContainerAccordionDisabled;
