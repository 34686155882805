import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Content } from './styles';
import Button from '../../Components/Button';
import logoImg from '../../Assets/cappteilogo.svg';

const SignIn: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const onClickRegisterLogin = (): void => {
    loginWithRedirect();
  };

  return (
    <Container>
      <Content>
        <img src={logoImg} alt="Capptei" />
        <Button type="button" onClick={onClickRegisterLogin}>
          Entrar
        </Button>
      </Content>
    </Container>
  );
};

export default SignIn;
