import React, { useState, useEffect } from 'react';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthProvider } from '../Hooks/auth0';
import Dashboard from '../Pages/Dashboard';
import Survey from '../Pages/Survey';
import Raffle from '../Pages/Raffle';
import Schedule from '../Pages/Schedule';
import Profile from '../Pages/Profile';
import UserFinishSignUp from '../Pages/UserFinishSign';
import SellingRaffles from '../Pages/SellingRaffles';
import api from '../Services/api';
import Loading from '../Components/Loading';
import * as LoadingAnimation from '../Assets/loading-animation.json';

const Private: React.FC = () => {
  const history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [userVerify, setUserVerify] = useState(false);
  useEffect(() => {
    const getAccessToken = async (): Promise<void> => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently({
          audience: 'https://api.apibackend.de/auth',
          scope: 'openid profile email',
        });
        setAccessToken(token);
        await api.get('user/verify_new_user', {
          params: {
            auth0_id: user.sub,
          },
          headers: {
            Authorization: (api.defaults.headers.Authorization = `Bearer ${token}`),
          },
        });
        setUserVerify(true);
        setLoading(false);
      } catch (e) {
        history.push('/finish');
        setLoading(false);
      }
    };
    getAccessToken();
  }, [getAccessTokenSilently, user, history]);

  const notFoundPage = (): JSX.Element => <Redirect to="/dashboard" />;
  if (accessToken === '' || loading === true) {
    return (
      <div>
        <Loading animation={LoadingAnimation} width={100} height={100} />
      </div>
    );
  }

  return (
    <AuthProvider accessToken={accessToken}>
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/survey" component={Survey} />
        <Route path="/raffle" component={Raffle} />
        <Route path="/schedule" component={Schedule} />
        <Route path="/profile" component={Profile} />
        <Route
          path="/finish"
          component={userVerify ? notFoundPage : UserFinishSignUp}
        />
        <Route path="/selling-raffles/:id" component={SellingRaffles} />
        <Route path="*" component={notFoundPage} />
      </Switch>
    </AuthProvider>
  );
};

export default Private;
