import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { FiArrowLeft } from 'react-icons/fi';
import {
  ListEventsContainer,
  ContainerImg,
  ContainerItem,
  ContainerSubItem,
  ContainerText,
  RowInfo,
  SubContainer,
  TitleContainer,
} from './styles';
import {
  iconsEvents,
  formatSqlDateToDateFormat,
} from '../../../utils/functions';
import { MySubCalendarProps } from '../../../interfaces';

import 'react-toastify/dist/ReactToastify.css';
import Title from '../../Title';

import { Container } from '../../Modal/styles';

const ListEvents = ({
  resource,
  start,
  title,
}: MySubCalendarProps): React.ReactElement => {
  const [visible, setVisible] = useState(false);

  function handleClose(): void {
    setVisible(false);
  }
  return (
    <ListEventsContainer onClick={() => setVisible(!visible)}>
      <ContainerItem>
        <ContainerImg>
          <img src={iconsEvents[resource.typeEventId - 1]} alt="ícone" />
        </ContainerImg>
        <ContainerSubItem>
          <ContainerText title={title}>{title}</ContainerText>
          <RowInfo
            style={
              resource.reason === ''
                ? { justifyContent: 'flex-end' }
                : { justifyContent: 'space-between' }
            }
          >
            {resource.reason === '' ? '' : <h6>{resource.reason}</h6>}
            <h6>
              {formatSqlDateToDateFormat(
                start.toString(),
                resource.typeEventId,
              )}
            </h6>
          </RowInfo>
        </ContainerSubItem>
      </ContainerItem>
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Container>
          <SubContainer>
            <TitleContainer>
              <FiArrowLeft
                size={28}
                style={{ marginRight: 10 }}
                color="#737373"
                onClick={handleClose}
              />
              <Title>{title}</Title>
            </TitleContainer>
          </SubContainer>
        </Container>
      </Modal>
    </ListEventsContainer>
  );
};

export default ListEvents;
