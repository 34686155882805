import styled, { css } from 'styled-components';

export const AccordionWrapper = styled.div`
  width: 90%;
  background: ${(props) => props.color};
  border-radius: 8px;
  margin: 0.4em;
  height: 70px;
  & + * {
    margin-top: 0.5em;
  }
`;

type AccordionItemProps = {
  isOpen: boolean;
};

export const AccordionItem = styled.div<AccordionItemProps>`
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  ${(props) =>
    props.isOpen &&
    css`
      max-height: 0;
      transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    `}
`;

type AccordionTitleProps = {
  isOpen: boolean;
};

export const AccordionTitle = styled.div<AccordionTitleProps>`
  font-weight: 600;
  cursor: pointer;
  color: #ffffff;
  padding: 0.5em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
  }
  ${(props) =>
    props.isOpen &&
    css`
      &::after {
        content: '';
        border-top: 0;
        border-bottom: 5px solid;
      }
    `}
`;

export const AccordionContent = styled.div`
  padding: 1em 1.5em;
`;

export const SubContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 1.5em;
`;

export const AccordionText = styled.h6`
  font-family: 'Montserrat';
  font-size: 12px;
  color: white;
`;
