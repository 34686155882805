import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { Container } from './styles';
import ButtonFormat from '../ButtonFormat';
import { ModalProps } from '../../interfaces';

const ModalComponent: React.FC<ModalProps> = ({
  TextButton,
  children,
}: ModalProps) => {
  const [visible, setVisible] = useState(false);

  function handleOpen(): void {
    setVisible(true);
  }
  function handleClose(): void {
    setVisible(false);
  }
  return (
    <>
      <ButtonFormat type="button" onClick={() => handleOpen()}>
        {TextButton}
      </ButtonFormat>
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Container>{children}</Container>
      </Modal>
    </>
  );
};

export default ModalComponent;
