import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { Container, Content, Background, ContainerForm } from './styles';
import Input from '../../Components/Input';
import Button from '../../Components/Button';
import Title from '../../Components/Title';
import api from '../../Services/api';
import { formatDateToSqlFormat } from '../../utils/functions';
import { NewUserProps } from '../../interfaces';

const UserFinishSignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { user } = useAuth0();

  const handleSignIn = useCallback(
    async (data: NewUserProps) => {
      const formData = {
        auth0_id: user.sub,
        email: user.email,
        born_date: formatDateToSqlFormat(data.bornDate),
        country: data.country,
        cpf: data.cpf,
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
        postal_code: data.postalCode,
        team_pass: data.teamPass,
      };
      try {
        await api.post('/user/new_user', formData);
        history.push('/dashboard');
      } catch (error) {
        console.log(error);
      }
    },
    [user, history],
  );

  return (
    <Container>
      <Background />
      <Content>
        <ContainerForm ref={formRef} onSubmit={handleSignIn}>
          <Title>Complete seu cadastro</Title>
          <Input name="firstName" type="text" placeholder="Nome" />
          <Input name="lastName" type="text" placeholder="Sobrenome" />
          <Input name="cpf" type="text" placeholder="CPF" mask="cpf" />
          <Input name="country" type="text" placeholder="País" />
          <Input name="postalCode" type="text" placeholder="CEP" />
          <Input name="teamPass" type="text" placeholder="Código da turma" />
          <Input
            name="bornDate"
            placeholder="Data de nascimento"
            type="text"
            mask="date"
          />
          <Input name="phone" type="text" placeholder="Telefone" mask="phone" />
          <Button type="submit">Finalizar</Button>
        </ContainerForm>
      </Content>
    </Container>
  );
};
export default UserFinishSignUp;
