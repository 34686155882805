import styled from 'styled-components';

export const ButtonBodyComponent = styled.button`
  display: flex;
  flex-direction: row;
  width: 180px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border: 0;
  background: linear-gradient(135deg, #65e8ff 0%, #44d8b4 100%);
  border-radius: 15px;
  color: #ffffff;

  @media (max-width: 600px) {
    width: 100px;
    height: 80px;
    justify-content: space-evenly;

    flex-direction: column;
  }
`;

export const ImgBodyComponent = styled.img`
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 4px;
  margin-right: 8px;
  background: #009eba;

  @media (max-width: 600px) {
    margin-right: 0;
  }
`;
