import React, { useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import { Modal } from '@material-ui/core';
import { FiArrowLeft } from 'react-icons/fi';
import { ContainerForm, SubContainer, TitleContainer } from './styles';
import api from '../../../Services/api';
import 'react-toastify/dist/ReactToastify.css';
import ButtonFormat from '../../ButtonFormat';
import Button from '../../Button';
import Input from '../../Input';
import Title from '../../Title';
import { useCapptei } from '../../../Hooks/auth0';

import { Container } from '../../Modal/styles';
import { GenerateToast } from '../../../utils/functions';

const CreateEvent: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { count, setCount } = useCapptei();

  const [visible, setVisible] = useState(false);
  function handleOpen(): void {
    setVisible(true);
  }
  function handleClose(): void {
    setVisible(false);
  }

  const handleSubmit = useCallback(
    async (data) => {
      try {
        await api.post('/events/create', data);
        setCount(count + 1);
        handleClose();
        toast.success('Seu evento foi criado com sucesso!', GenerateToast());
      } catch (error) {
        setCount(count + 1);
        handleClose();
        toast.error('Seu evento não foi criado!', GenerateToast());
      }
    },
    [count, setCount],
  );

  return (
    <>
      <ButtonFormat onClick={() => handleOpen()}>Criar Evento</ButtonFormat>
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Container>
          <SubContainer>
            <TitleContainer>
              <FiArrowLeft
                size={28}
                style={{ marginRight: 10 }}
                color="#737373"
                onClick={() => handleClose()}
              />
              <Title>Criar Evento</Title>
            </TitleContainer>

            <ContainerForm ref={formRef} onSubmit={handleSubmit}>
              <Input name="title" placeholder="Título" />
              <Input
                name="date"
                placeholder="Data"
                mask="date"
                maxLength={10}
              />
              <Input name="local" placeholder="Local" />
              <Input name="reason" placeholder="Motivo" />

              <Button type="submit">Criar evento</Button>
            </ContainerForm>
          </SubContainer>
        </Container>
      </Modal>
    </>
  );
};

export default CreateEvent;
