import React, { useState, useRef, useCallback } from 'react';

import { Modal } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { Container } from '../../../Modal/styles';
import TransferLogo from '../../../../Assets/transfer.svg';
import ButtonBody from '..';
import Title from '../../../Title';
import Input from '../../../Input';
import Button from '../../../Button';
import { SubContainer, FormContainer, TitleContainer } from './styles';
import api from '../../../../Services/api';
import { GenerateToast } from '../../../../utils/functions';

const TransactionButton: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  function handleOpen(): void {
    setVisible(true);
  }
  function handleClose(): void {
    setVisible(false);
  }
  const handlePayment = useCallback(
    async (data) => {
      try {
        await api.post('survey/new_transaction_survey', data);
        toast.success('A enquete de transação foi criada!', GenerateToast());
        setVisible(false);
        history.push('/survey');
      } catch (error) {
        toast.error(
          'A enquete de transação não foi criada, tente novamente',
          GenerateToast(),
        );
      }
      setVisible(false);
    },
    [history],
  );
  return (
    <>
      <ButtonBody
        activePowerButton={() => handleOpen()}
        text="Gerar Transação"
        img={TransferLogo}
      />
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Container>
          <SubContainer>
            <div />
            <TitleContainer>
              <FiArrowLeft
                size={28}
                style={{ marginRight: 10 }}
                color="#737373"
                onClick={() => handleClose()}
              />
              <Title>Gerar Transação</Title>
            </TitleContainer>
            <FormContainer ref={formRef} onSubmit={handlePayment}>
              <Input name="title" placeholder="Título" />
              <Input
                name="conclusion"
                placeholder="Data de Encerramento"
                mask="date"
              />
              <Input
                name="amount"
                placeholder="Valor a ser transferido"
                mask="currency"
              />
              <Input name="reason" placeholder="Motivo" />

              <Button type="submit" style={{ marginBottom: 20 }}>
                Gerar Transação
              </Button>
            </FormContainer>
          </SubContainer>
        </Container>
      </Modal>
    </>
  );
};

export default TransactionButton;
