import React from 'react';
import Modal from '@material-ui/core/Modal';
import { ModalSurveyProps } from '../../../interfaces';

const ModalAnswer: React.FC<ModalSurveyProps> = ({
  isVisible,
  isClose,
  children,
}) => {
  return (
    <Modal
      open={isVisible}
      onClose={isClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div>{children}</div>
    </Modal>
  );
};

export default ModalAnswer;
