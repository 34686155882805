import React from 'react';
import Lottie from 'react-lottie';
import { Container } from './styles';
import { LoadingProps } from '../../interfaces';

const Loading: React.FC<LoadingProps> = ({
  animation,
  height,
  width,
  hasContainerHeight = false,
}: LoadingProps) => {
  const options = {
    loop: true,
    autoplay: true,
    animationData: animation.default,
  };

  return (
    <Container hasHeight={hasContainerHeight}>
      <Lottie options={options} width={width} height={height} />
    </Container>
  );
};

export default Loading;
