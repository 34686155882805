import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background: linear-gradient(135deg, #65e8ff 0%, #44d8b4 100%);
  width: 80%;
  margin-top: 8px;
  border-radius: 8px;
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const ContainerDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`;

export const LinkStyle = styled(Link)`
  text-decoration: none;
`;

export const TitleCard = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: #ffffff;
  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
`;
export const CardDiv = styled.div`
  width: 25%;
`;

export const ContainerText = styled.div`
  text-align: left;
  padding: 20px;
  p {
    font-size: 18px;
    color: #ffffff;
    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 700px) {
    padding: 10px;
  }
`;
export const TextStyle = styled.h6`
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`;
