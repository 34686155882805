import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerSurveys = styled.div`
  width: 100%;
  display: grid;
  justify-content: space-between;
  justify-items: center;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const Content = styled.div`
  margin: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #009eba;
    margin-bottom: 24px;
    font-size: 36px;
    font-weight: bold;
  }
  @media only screen and (max-width: 700px) {
    margin: 70px auto;
  }
`;

export const SurveyText = styled.p`
  font-size: 16px;
  color: black;
`;
