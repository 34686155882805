import React, { ButtonHTMLAttributes } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { Container, ResponseDiv } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

const ButtonFormat: React.FC<ButtonProps> = ({
  children,
  loading,
  ...rest
}) => (
  <Container type="button" {...rest}>
    {loading ? (
      'Carregando'
    ) : (
      <ResponseDiv>
        <BsPlusCircle style={{ marginRight: 8 }} />
        {children}
      </ResponseDiv>
    )}
  </Container>
);

export default ButtonFormat;
