import React, { useEffect, useState } from 'react';

import Header from '../../Components/Header';
import Sidebar from '../../Components/Sidebar';
import { Container, Content, ContainerSurveys, SurveyText } from './styles';
import ResponseSurveys from '../../Components/SurveyComponents/ResponseSurveys';
import CreateSurvey from '../../Components/SurveyComponents/CreateSurvey';
import api from '../../Services/api';
import { FinishDateToSurvey } from '../../utils/functions';
import { SurveyProps, SuveryDisabledProps } from '../../interfaces';
import { useCapptei } from '../../Hooks/auth0';
import PermissionComponent from '../../Components/PermissionComponent';
import AccordionComponent from '../../Components/SurveyComponents/AccordionComponent';
import ContainerAccordionDisabled from '../../Components/SurveyComponents/ContainerAccordionDisabled';

import * as LoadingAnimation from '../../Assets/loading-animation.json';
import LoadingComponent from '../../Components/Loading';

const Survey: React.FC = () => {
  const [surveys, setSurveys] = useState([]);
  const [surveysDisabled, setSurveysDisabled] = useState([]);
  const [surveysTransaction, setSurveysTransaction] = useState([]);
  const [surveysDisabledTransaction, setSurveysDisabledTransaction] = useState(
    [],
  );
  const { count } = useCapptei();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoading(true);
      const response = await api.get('/survey/surveys_enabled');
      const responseDisabled = await api.get('/survey/surveys_disabled');
      const transactionDisabled = await api.get(
        '/survey/surveys_transaction_disabled',
      );
      setSurveys(response.data.survey);
      setSurveysDisabled(responseDisabled.data.survey);
      setSurveysDisabledTransaction(transactionDisabled.data.survey);
      try {
        const transaction = await api.get(
          '/survey/surveys_transaction_enabled',
        );
        setSurveysTransaction(transaction.data.survey);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    load();
  }, [count]);

  if (loading) {
    return (
      <Container>
        <Header />
        <Sidebar />
        <Content>
          <h1>Enquetes</h1>
          <LoadingComponent
            animation={LoadingAnimation}
            width={100}
            height={100}
            hasContainerHeight
          />
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Header />
      <Sidebar />
      <Content>
        <h1>Enquetes</h1>
        <PermissionComponent permissionAuth0="write:survey">
          <CreateSurvey />
        </PermissionComponent>
        <ContainerSurveys>
          <PermissionComponent permissionAuth0="write:survey">
            {surveysTransaction.map((item: SurveyProps) => (
              <AccordionComponent
                response="Não respondida"
                time={FinishDateToSurvey(item.final_date)}
                title={item.name}
                key={item.id}
                color="linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(15,242,41,1) 0%, rgba(5,255,114,1) 100%)"
              >
                <ResponseSurveys
                  key={item.id}
                  id={item.id}
                  options={item.alternatives}
                />
              </AccordionComponent>
            ))}
          </PermissionComponent>
          {surveys.map((item: SurveyProps) => (
            <AccordionComponent
              response="Não respondida"
              time={FinishDateToSurvey(item.final_date)}
              title={item.name}
              key={item.id}
              color="linear-gradient(169.46deg, #65e8ff 0%, #44d8b4 100%)"
            >
              <ResponseSurveys
                key={item.id}
                id={item.id}
                options={item.alternatives}
              />
            </AccordionComponent>
          ))}
        </ContainerSurveys>
        {surveysDisabledTransaction.length === 0 &&
        surveysDisabled.length === 0 ? (
          <SurveyText>Não existe enquetes finalizadas</SurveyText>
        ) : (
          <h1 style={{ color: '#ccc' }}>Finalizadas</h1>
        )}
        <ContainerSurveys>
          {surveysDisabledTransaction.map((item: SuveryDisabledProps) => (
            <ContainerAccordionDisabled
              key={item.id}
              title={item.name}
              votes={item.votes}
              options={item.alternatives}
            />
          ))}
          {surveysDisabled.map((item: SuveryDisabledProps) => (
            <ContainerAccordionDisabled
              key={item.id}
              title={item.name}
              votes={item.votes}
              options={item.alternatives}
            />
          ))}
        </ContainerSurveys>
        <div style={{ marginBottom: 100 }} />
      </Content>
    </Container>
  );
};

export default Survey;
