import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { Container, Content, Columns } from './styles';
import Input from '../../Components/Input';
import Button from '../../Components/Button';
import Sidebar from '../../Components/Sidebar';
import Header from '../../Components/Header';
import api from '../../Services/api';
import { ProfileProps } from '../../interfaces';
import { GenerateToast, StringDate } from '../../utils/functions';
import * as LoadingAnimation from '../../Assets/loading-animation.json';
import LoadingComponent from '../../Components/Loading';

const Profile: React.FC = () => {
  const { user } = useAuth0();
  const formRef = useRef<FormHandles>(null);
  const [dataUser, setDataUser] = useState<ProfileProps>({
    auth0: '',
    bornDate: '',
    country: '',
    cpf: '',
    email: '',
    last: '',
    name: '',
    phone: '',
    postal: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const response = await api.get(`/user/get/${user.sub}`);
      setDataUser(response.data);
      setIsLoading(false);
    };
    loadData();
  }, [user.sub]);

  const handleSubmit = useCallback(
    async (data: ProfileProps) => {
      try {
        await api.put(`user/edit/${user.sub}`, data);
        toast.success('Seus dados foram editados com sucesso', GenerateToast());
      } catch (error) {
        toast.error('Não foi possível editar seus dados', GenerateToast());
      }
    },
    [user],
  );

  if (isLoading) {
    return (
      <LoadingComponent animation={LoadingAnimation} width={100} height={100} />
    );
  }
  return (
    <Container>
      <Header />
      <Sidebar />
      <Content>
        <h1>Perfil</h1>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            bornDate: StringDate(dataUser.bornDate),
            cpf: dataUser.cpf,
            email: dataUser.email,
            last: dataUser.last,
            name: dataUser.name,
            phone: dataUser.phone,
          }}
        >
          <Columns>
            <Input name="name" type="text" placeholder="Nome" />
            <Input name="last" type="text" placeholder="Sobrenome" />
            <Input name="email" type="text" placeholder="E-mail" />
            <Input
              name="phone"
              type="text"
              placeholder="Telefone"
              mask="phone"
            />
            <Input name="cpf" type="text" placeholder="CPF" mask="cpf" />
            <Input
              name="bornDate"
              placeholder="Data de nascimento"
              type="text"
              mask="date"
            />
          </Columns>
          <Button type="submit" style={{ marginBottom: 20 }}>
            Salvar
          </Button>
          <div style={{ marginTop: 60 }} />
        </Form>
      </Content>
    </Container>
  );
};
export default Profile;
