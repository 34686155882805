import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignIn from '../Pages/SignIn';
import { AuthProvider } from '../Hooks/auth0';

const Public: React.FC = () => {
  const notFoundPage = (): JSX.Element => <Redirect to="/" />;

  return (
    <AuthProvider accessToken="">
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="*" component={notFoundPage} />
      </Switch>
    </AuthProvider>
  );
};

export default Public;
