import styled from 'styled-components';
import { shade } from 'polished';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';

export const ButtonHeaderLeft = styled.button`
  border: none;
  background: none;
  transition: color 0.2s;
  display: flex;
  &:hover {
    color: ${shade(0.2, '#c4c4c4')};
  }
`;

export const ButtonMenu = styled(MenuItem)`
  margin-bottom: 16px;
  h6 {
    font-size: 16px;
    font-family: Montserrat;
    color: #737373;
  }
`;

export const ContainerName = styled.div`
  width: 113px;
  height: 113px;
  border-radius: 180px;
  background: linear-gradient(180deg, #ffb800 5.73%, #ff5c00 100%);
  color: white;
  font-size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerBoxUserInfo = styled.div`
  height: 11vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;

  h4 {
    font-size: 20px;
    font-weight: bold;
    color: #737373;
  }
  h6 {
    font-size: 12px;
    color: #737373;
  }
`;

export const LinkButton = styled(Link)`
  background: linear-gradient(166.79deg, #65e8ff 0%, #44d8b4 100%);
  height: 20px;
  border: 0;
  padding: 2px 12px;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    background: ${shade(0.2, '#65e8ff')};
  }
`;
