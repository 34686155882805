import React from 'react';
import Header from '../../Components/Header';
import Sidebar from '../../Components/Sidebar';
import { Container, Content, Buttons } from './styles';
import Card from '../../Components/DashboardComponents/Card';
import Title from '../../Components/Title';
import BankSlipPayButton from '../../Components/DashboardComponents/Buttons/BankSlipPayButton';
import PermissionComponent from '../../Components/PermissionComponent';
import TransactionButton from '../../Components/DashboardComponents/Buttons/TransactionButton';
import TeamComponent from '../../Components/TeamComponent';

const Dashboard: React.FC = () => {
  return (
    <Container>
      <Header />
      <Sidebar />
      <Content>
        <Title>Resultados</Title>
        <PermissionComponent permissionAuth0="write:finance">
          <Buttons>
            <BankSlipPayButton />
            <PermissionComponent permissionAuth0="admin:team">
              <TeamComponent />
            </PermissionComponent>
            <TransactionButton />
          </Buttons>
        </PermissionComponent>
        <Card />
      </Content>
    </Container>
  );
};

export default Dashboard;
