import React, { createContext, useState, useContext } from 'react';
import api from '../Services/api';
import { AuthContextDate, AuthProviderProps } from '../interfaces';

const AuthContext = createContext<AuthContextDate>({} as AuthContextDate);

const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  accessToken,
}: AuthProviderProps) => {
  const [count, setCount] = useState(0);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());

  if (accessToken !== '') {
    api.defaults.headers.Authorization = `Bearer ${accessToken}`;
  }

  const updateUser = (): void => {
    console.log('oi');
  };

  return (
    <AuthContext.Provider
      value={{
        updateUser,
        count,
        setCount,
        token: accessToken,
        currentMonth,
        setCurrentMonth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
function useCapptei(): AuthContextDate {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('UseAuth must be used within a AuthProvider');
  }
  return context;
}
export { AuthProvider, useCapptei };
