import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="capptei.us.auth0.com"
      clientId="XfBYuCCPMhIYT50tHA8VD5ivxsEtW3Is"
      redirectUri={window.location.origin}
      scope="read:current_user update:current_user_metadata"
      audience="https://api.apibackend.de/auth"
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
serviceWorkerRegistration.register();
