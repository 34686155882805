import styled from 'styled-components';

export const Container = styled.div`
  background: #f2f2f2;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: flex-start;
  justify-content: center;
  margin: 4px;
  border-radius: 8px;
  box-shadow: 0 0 4px 0px black;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
`;

export const TitleDisabled = styled.h5`
  color: #a0a0a0;
  font-weight: bold;
  font-size: 24px;
  font-family: 'Montserrat';
`;

export const TextInfoDisabled = styled.h6`
  color: #c9c9c9;
  font-size: 16px;
  font-family: 'Montserrat';
  margin-left: 8px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
