import styled from 'styled-components';

export const DaysOfCalendarContainer = styled.div`
  height: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DaysOfCalendarText = styled.div`
  position: absolute;
  bottom: -20px;
  font-size: 17px;
`;

export const DaysOfCalendarDot = styled.div`
  height: 7px;
  width: 7px;
  background: linear-gradient(180deg, #ffb800 5.73%, #ff5c00 100%);
  border-radius: 50%;
  display: inline-block;
`;
