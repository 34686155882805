export const cep = (
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 9;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{5})(\d)/, '$1-$2');
  e.currentTarget.value = value;
  return e;
};

export const currency = (
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> => {
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

  e.currentTarget.value = value;
  return e;
};

export const cpf = (
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 14;
  let { value } = e.currentTarget;
  if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{2})$/, '$1-$2');
    e.currentTarget.value = value;
  }
  return e;
};

export const phone = (
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 13;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{0})(\d)/, '$1($2');
  value = value.replace(/^(\(\d{2})(\d)/, '$1)$2');
  e.currentTarget.value = value;
  return e;
};

export const date = (
  e: React.FormEvent<HTMLInputElement>,
): React.FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 10;
  let { value } = e.currentTarget;
  if (!value.match(/^(\d{2})\\(\d{2})\\(\d{4})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    e.currentTarget.value = value;
  }
  return e;
};
