import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Container } from './styles';
import primaryLogo from '../../Assets/iconLogo.svg';
import MenuComponent from '../Menu';
import DrawerComponent from '../Drawer';

// useEffect(() => {
//   const load = async (): Promise<void> => {
//     const response = await api.get('/user/profile_complete');
//     setUser(response.data);

//     try {
//       const permission = await api.get('/scheduler/get_code_classe');
//       localStorage.setItem('code_classe', permission.data);
//       localStorage.removeItem('notClass');
//     } catch (error) {
//       localStorage.setItem('notClass', error.response.data);
//     }
//   };
//   load();
// }, []);

const Header: React.FC = () => {
  const mobile = useMediaQuery({ query: '(max-width: 700px)' });

  return (
    <Container>
      <header>
        <div
          style={{
            width: '25%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {mobile ? <DrawerComponent /> : <MenuComponent />}
        </div>
        <div>
          <img src={primaryLogo} alt="Capptei" />
        </div>
        <div style={{ width: '25%' }} />
      </header>
    </Container>
  );
};

export default Header;
