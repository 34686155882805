import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import ModalAnswer from '../ModalAnswer';
import {
  Container,
  ContainerAnswer,
  ButtonYes,
  ButtonNo,
  ContainerDecision,
} from './styles';
import Title from '../../Title';
import 'react-toastify/dist/ReactToastify.css';
import RadioButton from '../../RadioButton';
import api from '../../../Services/api';
import { ResponseSurveysProps } from '../../../interfaces';
import { useCapptei } from '../../../Hooks/auth0';
import { GenerateToast } from '../../../utils/functions';

const ResponseSurveys: React.FC<ResponseSurveysProps> = ({ id, options }) => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState('');
  const formRef = React.useRef<FormHandles>(null);
  const [, setIsLoading] = useState(false);
  const { count, setCount } = useCapptei();

  function handleModalAnswer(): void {
    setVisible(!visible);
  }
  function handleText(data: string): void {
    setText(data);
    handleModalAnswer();
  }
  const handleToVote = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        await api.put(`/survey/answer/${id}`, { alternative: data });
        setCount(count + 1);
        setIsLoading(false);
        toast.success('Voto efetuado com sucesso!', GenerateToast());
      } catch (error) {
        setIsLoading(false);
        toast.error('Voto não foi efetuado, tente novamente!', GenerateToast());
      }
    },
    [count, id, setCount],
  );

  return (
    <div>
      <Form
        ref={formRef}
        onSubmit={() => {}}
        style={{
          display: 'flex',
          flexDirection: 'column',
          color: '#ffffff',
        }}
      >
        <RadioButton
          name="alternative"
          options={options}
          onChange={(optionText) => {
            handleText(optionText.target.value);
          }}
          key={id}
        />
      </Form>
      <ModalAnswer isClose={handleModalAnswer} isVisible={visible}>
        <>
          <Container>
            <ContainerAnswer>Confirme sua resposta</ContainerAnswer>
            <Title>{text}</Title>

            <ContainerDecision>
              <ButtonYes
                type="button"
                onClick={() => {
                  handleModalAnswer();
                  handleToVote(text);
                }}
              >
                Confirmar
              </ButtonYes>
              <ButtonNo type="button" onClick={() => handleModalAnswer()}>
                Voltar
              </ButtonNo>
            </ContainerDecision>
          </Container>
        </>
      </ModalAnswer>
    </div>
  );
};

export default ResponseSurveys;
