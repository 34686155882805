/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header';
import Sidebar from '../../Components/Sidebar';
import { Container, Content, RaffleText } from './styles';
import CardButton from '../../Components/RaffleComponents/CardButton';
import Title from '../../Components/Title';
import CreateRaffle from '../../Components/RaffleComponents/CreateRaffle';
import api from '../../Services/api';

import LoadingComponent from '../../Components/Loading';
import * as LoadingAnimation from '../../Assets/loading-animation.json';
import { useCapptei } from '../../Hooks/auth0';
import { RaffleProps } from '../../interfaces';
import PermissionComponent from '../../Components/PermissionComponent';

const Raffle: React.FC = () => {
  const [getRaffles, setGetRaffles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { count } = useCapptei();
  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const response = await api.get('/raffle/get_raffle');
        setGetRaffles(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    load();
  }, [count]);

  if (isLoading) {
    return (
      <Container>
        <Header />
        <Sidebar />
        <Content>
          <Title>Rifas</Title>
          <LoadingComponent
            animation={LoadingAnimation}
            width={100}
            height={100}
            hasContainerHeight
          />
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Header />
      <Sidebar />
      <Content>
        <Title>Rifas</Title>
        <PermissionComponent permissionAuth0="write:raffle">
          <CreateRaffle />
        </PermissionComponent>
        {getRaffles.length === 0 ? (
          <RaffleText>Não existe rifa disponível</RaffleText>
        ) : (
          getRaffles.map((data: RaffleProps) => (
            <CardButton
              sendTo={`Selling-raffles/${data.raffle_id}`}
              description={data.description}
              dateFinal={data.final_collection_date}
              dateSorted={data.draw_date}
              name={data.name}
              key={data.raffle_id}
            />
          ))
        )}
        <div style={{ marginBottom: 100 }} />
      </Content>
    </Container>
  );
};

export default Raffle;
