import React from 'react';
import { DaysOfCalendarProps } from '../../../interfaces';
import {
  DaysOfCalendarContainer,
  DaysOfCalendarText,
  DaysOfCalendarDot,
} from './styles';

const DaysOfCalendar = ({
  day,
  modifiers,
}: DaysOfCalendarProps): React.ReactElement => {
  const date = day.getDate();
  return (
    <DaysOfCalendarContainer>
      <DaysOfCalendarText
        style={{
          color: modifiers.disabled ? '#C4C4C4' : '#5A5A5A',
        }}
      >
        {date}
      </DaysOfCalendarText>
      {modifiers.selected && <DaysOfCalendarDot />}
    </DaysOfCalendarContainer>
  );
};

export default DaysOfCalendar;
